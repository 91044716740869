import { Component, OnInit } from '@angular/core';
import { EquiposService } from '../equipos.service';
import { OperadoresService } from '../operadores.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CamionComponent } from '../camion/camion.component';
import { ReportsService } from '../reports.service';


@Component({
  selector: 'app-tabla-camiones',
  templateUrl: './tabla-camiones.component.html',
  styleUrls: ['./tabla-camiones.component.css']
})
export class TablaCamionesComponent implements OnInit {
  camiones;
  maquinas;
  equipoModal;
  obras;
  equipos;
  loading: boolean = true;
  constructor(private equiposService: EquiposService, private operadorService: OperadoresService, private modalService: NgbModal) { }
  pasaModal = (equipo) => {
    this.equipoModal = equipo;
  }
  async ngOnInit() {
    
    this.equipos = await this.equiposService.getCamiones();
    this.camiones = this.equipos.filter((equipo) => {
      if (equipo.MAQUINA) {
        return false
      } else {
        return true
      }

    })
    this.maquinas = this.equipos.filter((equipo) => {
      if (equipo.MAQUINA) {
        return true
      } else {
        return false
      }

    })
    this.loading = false
    this.obras = await this.operadorService.getObras()
    

  }

  recargaDatos = async (evento) => {

    this.camiones = []

  }
  open(equipo) {

    const modalRef = this.modalService.open(CamionComponent, { size: 'lg' });
    modalRef.componentInstance.equipo = Object.assign({}, equipo);
    modalRef.componentInstance.obras2 = []
    this.obras.forEach(obra => {
      modalRef.componentInstance.obras2.push(JSON.parse(JSON.stringify(obra)))


    });




  }
}
