import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperadoresService {
  URL1 = 'http://localhost:3000/operadores';

  constructor() { }

  getOperadores = async () => {

    let operad = await fetch(this.URL1);
    operad = await operad.json();

    return operad.body;

  }
  getObras = async () => {
    let obras = await fetch('http://localhost:3000/obras')
    obras = await obras.json();
    return obras.body;

  }
  getMaterial = async () => {
    let obras = await fetch('http://localhost:3000/material')
    obras = await obras.json();
    return obras.body;
  }
  getDestinos = async () => {
    let destinos = await fetch('http://localhost:3000/obras/destinos')
    destinos = await destinos.json();
    return destinos.body;

  }
}
