import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquiposService {
  URL1 = 'http://localhost:3000/camion';
  URL2 = 'http://localhost:3000/maquina';
  constructor() { }

  getCamiones = async () => {

    let camiones = await fetch(this.URL1);
    camiones = await camiones.json();
    console.log(1)
    

    return camiones.body;

  }
}
