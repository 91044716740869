import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EquiposService } from '../equipos.service';
import { OperadoresService } from '../operadores.service';
import { async } from '@angular/core/testing';
import { ReportsService } from '../reports.service';
import { ReportFormComponent } from '../report-form/report-form.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  encontrado: boolean;
  encontrados;
  equipo
  operador
  Desde
  Hasta
  obra
  obras
  operadores
  equipos
  // datos = {
  //   equipo: this.equipo.PATENTE || "",
  //   operador: this.operador.RUT || "",
  //   fecha1: this.Desde || "",
  //   fecha2: this.Hasta || "",
  //   obra: this.obra.ID || ""
  // }

  constructor(private abrirModal: NgbModal, private equiposService: EquiposService,
    private reportService: ReportsService,
    private operadoresService: OperadoresService) { }

  async ngOnInit() {

    this.operadores = await this.operadoresService.getOperadores();
    this.equipos = await this.equiposService.getCamiones();
    this.obras = await this.operadoresService.getObras();
  }
  open() {
    const reportForm = this.abrirModal.open(ReportFormComponent,{size:"lg"})
  }

}
