import { Component, OnInit } from '@angular/core';
import { OperadoresService } from '../operadores.service';

@Component({
  selector: 'app-operadores',
  templateUrl: './operadores.component.html',
  styleUrls: ['./operadores.component.css']
})
export class OperadoresComponent implements OnInit {

  public operadores;
  public obras;
  constructor(private operadorService:OperadoresService) { }

  async ngOnInit() {
    this.operadores= await this.operadorService.getOperadores();
    this.obras =await this.operadorService.getObras();
    console.log(this.operadores)
    console.log(this.obras)
  }

}
