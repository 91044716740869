import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  URL1 = 'http://localhost:3000/reports';
  constructor() { }
  async getReports() {
    let reports = await fetch(this.URL1);
    reports = await reports.json();

    return reports.body;

  }


  async prueba() {
    const data = {
      N_REPORT: '45',
      FECHA: '2019-12-18',
      PATENTE: "DHDR-13",
      N_OPERADOR: "11.320.239-4",
      KM_INICIAL: 2,
      KM_FINAL: 3,
      HORA_INICIAL: 2,
      HORA_FINAL: 3,
      OBSERVACIONES: "asd",
      N_OBRA: 3,
      MAQUINA: 0,
      VIAJES: [{
        ORIGEN_ID: 1,
        NUMERO_VALE: 23,
        DESTINO_ID: 1,
        MATERIAL_ID: 3,
        VALE_TERCERO: 4,
        REPORT_C: 45
      }, {
        ORIGEN_ID: 1,
        NUMERO_VALE: 5,
        DESTINO_ID: 1,
        MATERIAL_ID: 3,
        VALE_TERCERO: 0,
        REPORT_C: 45,
      }]
    }
    let enviado = await fetch('http://localhost:3000/reports', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    enviado = await enviado.json()
    console.log(enviado)
    console.log(enviado.body)
  }

  async getReportFilter(filtro) {
    let ultimos = await fetch('http://localhost:3000/reports/buscar', {
      method: 'POST',
      body: JSON.stringify(filtro),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    ultimos = await ultimos.json()
    console.log(ultimos)
    console.log(ultimos.body)
    return ultimos.body;
  }


  async postReport(body1) {
    const URL2 = 'http://localhost:3000/reports'
    let enviado = await fetch(URL2, {
      method: 'POST',
      body: JSON.stringify(body1),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    enviado = await enviado.json()
    console.log(enviado)
    console.log(enviado.body)
  }
  async getOneVale(id: number) {
    let vale: any
    console.log(this.URL1 + '/vale' + '/' + id)
    vale = await fetch(this.URL1 + '/vale' + '/' + id)

    vale = await vale.json()

    if (!vale.error) {
      if (vale.body.length == 0) {
        vale = 'libre'
        return vale;

      }
      vale = vale.body[0]
      if (vale == '') {

      }
    } else {
      vale = 'libre'
    }
    return vale;

  }

  async getOneReport(id: number) {

    let report: any
    try {

      report = await fetch(this.URL1 + '/' + id)

    } catch (err) {
      console.log('paso algo')
    }

    report = await report.json();


    if (!report.error) {

      report = report.body[0]
    } else {
      report = 'libre'
    }
    return report;




  }
}
