import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-agregarviajes',
  templateUrl: './agregarviajes.component.html',
  styleUrls: ['./agregarviajes.component.css']
})
export class AgregarviajesComponent implements OnInit {
  @ViewChild('name', { static: false }) reportfield: ElementRef;
  @Output() enviar: EventEmitter<any> = new EventEmitter();
  @Input() obras;
  @Input() N_OBRA;
  @Input() destinos;
  @Input() ORIGEN;
  @Input() ViajesAlMomento: any[];
  @Input() MATERIAL;
  DESTINO;
  VALE_TERCERO;
  Material_fin
  numeroOK: boolean = false;
  NUMERO_VALE;
  constructor(private activeModal: NgbActiveModal, private reportService: ReportsService) {

    // this.ORIGEN_ID=2
  }
  get esOK() {
    return this.numeroOK
  }
  ngOnInit() {
    this.ORIGEN = this.N_OBRA;
  }
  async revisarNumero() {
    console.log(this.ViajesAlMomento)
    let resp1: boolean = false
    this.ViajesAlMomento.forEach(viaje => {
      if (viaje.NUMERO_VALE == this.NUMERO_VALE) {
        console.log('aca')
        this.numeroOK = false;
        resp1 = true
        return;

      }

    })
    if (resp1) {
      return
    }

    const
      resp = await this.reportService.getOneVale(this.NUMERO_VALE)
    console.log(resp)
    if (resp == "libre") {
      this.numeroOK = true
      return;

    } else {
      this.numeroOK = false
      return;

    }



    ;
  }
  enviando() {

    this.enviar.emit({
      ORIGEN_ID: this.ORIGEN.ID,
      NUMERO_VALE: this.NUMERO_VALE,

      ORIGEN_NOMBRE: this.ORIGEN.NOMBRE,

      DESTINO_ID: this.DESTINO.ID_BOTADERO,
      DESTINO_NOMBRE: this.DESTINO.NOMBRE_BOTADERO,
      MATERIAL_ID: this.Material_fin.ID_MATERIAL || 1,
      VALE_TERCERO: this.VALE_TERCERO || 0
    })
    console.log(this.ViajesAlMomento)
    this.VALE_TERCERO = null
    this.NUMERO_VALE = null
    this.reportfield.nativeElement.focus()
  }
}
