import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OperadoresService } from '../operadores.service';
@Component({
  selector: 'app-camion',
  templateUrl: './camion.component.html',
  styleUrls: ['./camion.component.css']
})
export class CamionComponent implements OnInit {
  @Input() equipo: any;
  @Input() obras2: any;
  @Output() recarga = new EventEmitter<string>();
  public equipoEditable;
  jola: [];
  obrasEliminar: any[] = []
  obrasAgregar: any[] = []
  obraOp = true
  constructor(private operadorService: OperadoresService, private activeModal: NgbActiveModal) { }

  async ngOnInit() {


    this.equipoEditable = Object.assign({}, this.equipo)
  
    this.equipo.OBRAS.forEach(equipoObra => {
      this.obras2.forEach(obra => {
        if (obra.ID == equipoObra.ID) {
          obra.valor = 1;
        }

      })


    });
  }
  cerrar() {
    console.log(this.obrasAgregar)
    console.log(this.obrasEliminar)
    this.activeModal.close();

  }
  // agregare obra a array de agregar y quitar obras para alfinal enviar las consultas pertinentes
  agregarQuitarObras(obral) {
    this.equipoEditable.OBRAS.forEach(obra1 => {
      if (obral.ID == obra1.ID) {
        this.obraOp = false
        if (!obral.valor) {

          if (this.obrasEliminar.indexOf(obral) == -1) {

            this.obrasEliminar.push(obral)
          }
        } else {
          if (this.obrasEliminar.indexOf(obral) > -1) {
            const index = this.obrasEliminar.indexOf(obral)
            this.obrasEliminar.splice(index, 1)
          }
        }
      }
    })
    if (this.obraOp) {
      if (obral.valor) {
        if (this.obrasAgregar.indexOf(obral) == -1) {
          this.obrasAgregar.push(obral)
        }
      } else {
        const index = this.obrasAgregar.indexOf(obral)
        if (index > -1) {
          this.obrasAgregar.splice(index, 1)
        }

      }

    }
    this.obraOp = true
    
  }

}