import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-vista',
  templateUrl: './app-vista.component.html',
  styleUrls: ['./app-vista.component.css']
})
export class AppVistaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
