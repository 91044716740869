import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { ContactoComponent } from './contacto/contacto.component';
import { HomeComponent } from './home/home.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { FooterComponent } from './footer/footer.component';
import { OperadoresService } from './operadores.service';
import { EquiposService } from './equipos.service';
import { TablaCamionesComponent } from './tabla-camiones/tabla-camiones.component';
import { CamionComponent } from './camion/camion.component';
import { NavigatorAppComponent } from './navigator-app/navigator-app.component';
import { FooterAppComponent } from './footer-app/footer-app.component';
import { AppVistaComponent } from './app-vista/app-vista.component';
import { OperadoresComponent } from './operadores/operadores.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsEditComponent } from './reports-edit/reports-edit.component';
import { ObrasComponent } from './obras/obras.component';
import { ReportFormComponent } from './report-form/report-form.component';
import { ReportsService } from './reports.service';
import { AgregarviajesComponent } from './agregarviajes/agregarviajes.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigatorComponent,
    ContactoComponent,
    HomeComponent,
    ServiciosComponent,
    FooterComponent,
    TablaCamionesComponent,
    CamionComponent,
    NavigatorAppComponent,
    FooterAppComponent,
    AppVistaComponent,
    OperadoresComponent,
    ReportsComponent,
    ReportsEditComponent,
    ObrasComponent,
    ReportFormComponent,
    AgregarviajesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule

  ],
  providers: [OperadoresService, EquiposService, ReportsService],
  bootstrap: [AppComponent],
  entryComponents: [CamionComponent, ReportFormComponent, AgregarviajesComponent]
})
export class AppModule { }
