import { Component, OnInit, ɵConsole, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ControlContainer } from '@angular/forms';
import { ReportsService } from '../reports.service';
import { OperadoresService } from '../operadores.service';
import { EquiposService } from '../equipos.service';
import { AgregarviajesComponent } from '../agregarviajes/agregarviajes.component';

@Component({
  selector: 'report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.css']
})
export class ReportFormComponent implements OnInit {
  @ViewChild('name', { static: false }) reportfield: ElementRef;
  viajes: any[] = [];
  operadores;
  equipos;
  obras;
  reportAnterior;
  today = new Date();
  FECHA = `${this.today.getFullYear()}-${this.today.getMonth() + 1}-${this.today.getDate()}`;
  PATENTE;
  KM_INICIAL;
  N_OBRA;
  RUT;
  kmOno: boolean = false;
  HORA_INICIAL;
  KM_FINAL;
  HORA_FINAL;
  N_REPORT;
  N_OPERADOR;
  VALES;
  OBSERVACIONES;
  destinos;
  ReportValido: boolean
  MATERIAL;
  LOADING;
  constructor(private abrirModal: NgbModal, private equiposService: EquiposService,
    private reportService: ReportsService,
    private operadoresService: OperadoresService, private activeModal: NgbActiveModal) { }
  // private activeModal: NgbActiveModal
  async ngOnInit() {
    // this.reportService.prueba()
    this.operadores = await this.operadoresService.getOperadores();
    this.equipos = await this.equiposService.getCamiones();
    this.obras = await this.operadoresService.getObras();
    this.destinos = await this.operadoresService.getDestinos();
    this.MATERIAL = await this.operadoresService.getMaterial();
    // console.log(this.report.invalid)

  }
  abrirViajes() {
    console.log(this.N_OBRA)
    const modalref = this.abrirModal.open(AgregarviajesComponent);
    modalref.componentInstance.N_OBRA = this.N_OBRA;
    modalref.componentInstance.ORIGEN = this.N_OBRA
    modalref.componentInstance.obras = this.obras;
    modalref.componentInstance.ViajesAlMomento = this.viajes;
    modalref.componentInstance.destinos = this.destinos
    modalref.componentInstance.MATERIAL = this.MATERIAL
    modalref.componentInstance.enviar.subscribe((data) => {

      this.viajes.push(data);
      console.log(this.viajes)
    })
  }
  borrarViaje(indice) {

    this.viajes.splice(indice, 1);
  }
  async kmOnoKm() {

    if (this.PATENTE.MAQUINA) {

      this.kmOno = false
    } else {
      this.kmOno = true
    }

    await this.buscarUltimoReport()
  }
  async buscarUltimoReport() {
    this.buscarUltimoReport = null
    if (this.FECHA && this.PATENTE) {

      let datos = {
        equipo: this.PATENTE.PATENTE,
        operador: "",
        fecha1: "",
        fecha2: this.FECHA,
        obra: ""
      }
      try {

        this.reportAnterior = await this.reportService.getReportFilter(datos);
        this.reportAnterior = this.reportAnterior[0];
      } catch (err) { }
      console.log(this.reportAnterior)
    }

  }

  get tabla() {
    if (this.viajes.length > 0) { return true } else { return false }

  }

  get validarHora() {

    if (this.HORA_INICIAL && this.HORA_FINAL) {
      if (parseInt(this.HORA_FINAL, 10) < parseInt(this.HORA_INICIAL, 10)) {
        return false
      } else {
        console.log(this.reportAnterior)
        if (this.reportAnterior) {

          if (this.reportAnterior.HORA_FINAL) {

            if (parseInt(this.HORA_INICIAL, 10) < parseInt(this.reportAnterior.HORA_FINAL, 10)) {
              return false

            }
          }
        }
      }
      return true


    } else {
      if (this.PATENTE) {

        if (this.PATENTE.MAQUINA == 1) {
          return false

        } else if (!this.HORA_FINAL && !this.HORA_INICIAL) {
          return true
        }

      }

      return false
    }
  }


  // revisa el report despues de ingresarlo
  async revisarReport() {
    try {

      const
        resp = await this.reportService.getOneReport(this.N_REPORT)
      console.log(resp)
      if (resp == "libre") {
        this.ReportValido = true
        console.log(this.ReportValido)
      } else {
        this.ReportValido = false

      }
    } catch (err) {
      console.log('nada');
    };


  }

  get validarKm() {
    if (this.kmOno) {
      if (this.KM_FINAL >= this.KM_INICIAL) {
        return true
      }
    }
    return false
  }
  // despues sera el enviar
  async prueba() {
    let viajesAEnviar = [];

    // copia el array de objetos de viajes realizados para poder limpiarlo y enviarlo como corresponde a la API
    this.viajes.forEach(obra => {
      viajesAEnviar.push(JSON.parse(JSON.stringify(obra)))

    });
    console.log(viajesAEnviar)
    viajesAEnviar.map((viaje) => {
      delete viaje.ORIGEN_NOMBRE
      delete viaje.DESTINO_NOMBRE
      viaje.NUMERO_VALE = parseInt(viaje.NUMERO_VALE, 10)
      viaje.VALE_TERCERO = parseInt(viaje.VALE_TERCERO, 10)
      viaje.REPORT_C = parseInt(this.N_REPORT, 10)
    });
    console.log(viajesAEnviar)
    const enviar = {
      N_REPORT: parseInt(this.N_REPORT, 10),
      FECHA: this.FECHA,
      PATENTE: this.PATENTE.PATENTE,
      N_OPERADOR: this.RUT,
      KM_INICIAL: parseInt(this.KM_INICIAL, 10) || 0,
      KM_FINAL: parseInt(this.KM_FINAL, 10) || 0,
      HORA_INICIAL: parseInt(this.HORA_INICIAL, 10) || 0,
      HORA_FINAL: parseInt(this.HORA_FINAL, 10) || 0,
      OBSERVACIONES: this.OBSERVACIONES || 'no coment',
      N_OBRA: this.N_OBRA,
      MAQUINA: this.PATENTE.MAQUINA,
      VIAJES: viajesAEnviar
    }
    console.log(enviar)
    this.LOADING = true
    let resp = await this.reportService.postReport(enviar)
    console.log(resp);
    this.LOADING = false
    this.reportfield.nativeElement.focus()
  }
}



