import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ReportFormComponent } from '../report-form/report-form.component';

@Component({
  selector: 'app-navigator-app',
  templateUrl: './navigator-app.component.html',
  styleUrls: ['./navigator-app.component.css']
})
export class NavigatorAppComponent implements OnInit {

  constructor(private activeModal: NgbModal) { }

  ngOnInit() {
  }
  open() {
    const reportForm = this.activeModal.open(ReportFormComponent,{size:"lg"})
  }
}
