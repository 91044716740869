import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { TablaCamionesComponent } from './tabla-camiones/tabla-camiones.component';
import { OperadoresComponent } from './operadores/operadores.component';
import { ReportFormComponent } from './report-form/report-form.component';
import { ReportsComponent } from './reports/reports.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'contact', component: ContactoComponent},
  {path: 'app/servicios', component: ServiciosComponent},
  {path: 'app', component: ReportsComponent},
  {path: 'app/operadores', component: OperadoresComponent},
  {path: 'app/reporta', component: ReportFormComponent},
  {path: 'app/equipos', component: TablaCamionesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
