import { Component } from '@angular/core';
import { OperadoresService } from './operadores.service';
import { EquiposService } from './equipos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bygApp';
  
  constructor(private operadorService: OperadoresService, private equiposService: EquiposService, public router: Router) { }

  async ngOnInit() {
 
    

  }
}
